import useAppConfig from '@/@core/@app-config/useAppConfig'
import useCommon from '@/composables/useCommon'
import useFirestoreCollectionNames from '@/composables/useFirestoreCollectionNames'
import { computed, ref, watch } from '@vue/composition-api'
import {
  addDoc,
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from 'firebase/firestore'
import { chunk, filter, isNil, orderBy as sortOrder } from 'lodash'
import { db } from '/src/firebase/config'

export default function useSelfDriveList() {
  const { country } = useAppConfig()
  const typeKey = country.value == 'UK' ? 'ltype' : 'markltype'
  const imgcarmobKey = country.value == 'UK' ? 'imgcarmob' : 'markimgcarmob'
  const { selfDriveCarCollectionName } = useFirestoreCollectionNames()

  const selfDriveCollection = collection(db, selfDriveCarCollectionName.value)
  const { getOptions, getCount, createOptions, stringContainsAny } = useCommon()
  let blankSelfDriveData = {
    active: true,
    agent_active: [],
    agent_name: [],
    agent_show: [],
    brandactive: null,
    brandclass: '',
    brandimg: '',
    carbrand: '',
    carclass: '',
    carfleet_id: '',
    carfleetactive: null,
    deposit: null,
    locactive: null,
    location: '',
  }

  if (country.value == 'UK') {
    blankSelfDriveData = {
      ...blankSelfDriveData,
      firstrate: null,
      secondrate: null,
      weeklyrate: null,
      excessamt: null,
      bookingamt: null,
    }
  } else {
    blankSelfDriveData = {
      ...blankSelfDriveData,
      color: '',
      launch_year: '',
      package_hours: '24',
      kms_limit: null,
      extra_km_charge: null,
      price: null,
    }
  }
  blankSelfDriveData[typeKey] = ''
  blankSelfDriveData[imgcarmobKey] = ''

  const selfDriveData = ref(JSON.parse(JSON.stringify(blankSelfDriveData)))

  const selfDrive = ref([])
  const filteredSelfDrive = ref([])
  const paginatedSelfDrive = ref([])
  const error = ref(null)
  const page = ref(1)
  const itemsPerPage = ref(30)

  const searchQuery = ref('')
  const statusFilter = ref('Active')
  const typeFilter = ref(null)
  const locationFilter = ref(null)
  const brandFilter = ref(null)
  const sortBy = ref(null)
  const loading = ref(false)
  let unsubscribe = null
  const options = ref({
    sortBy: ['updatedon'],
    sortDesc: [true],
  })

  const statusOptions = computed(() => {
    const mappedValuesWithCount = getCount(selfDrive.value, 'active')

    if (mappedValuesWithCount['true']) mappedValuesWithCount['Active'] = mappedValuesWithCount['true']
    if (mappedValuesWithCount['false']) mappedValuesWithCount['Inctive'] = mappedValuesWithCount['false']
    delete mappedValuesWithCount['true']
    delete mappedValuesWithCount['false']
    return createOptions(mappedValuesWithCount)
  })

  const typeOptions = computed(() => {
    return getOptions(selfDrive.value, typeKey)
  })

  const locationOptions = computed(() => {
    return getOptions(selfDrive.value, 'location')
  })

  const brandOptions = computed(() => {
    return getOptions(selfDrive.value, 'carbrand')
  })

  const queryOptions = () => {
    const qOptions = []

    qOptions.push(where('agent_active', 'array-contains', true))
    qOptions.push(where('brandactive', '==', true))
    qOptions.push(where('locactive', '==', true))
    qOptions.push(where('carfleetactive', '==', true))

    const orderByKey = options.value.sortBy[0] ? options.value.sortBy[0] : 'updatedon'
    const order = options.value.sortDesc[0] ? 'desc' : 'asc'
    qOptions.push(orderBy(orderByKey, order))

    return qOptions
  }

  // fetch data
  const fetchSelfDrive = async () => {
    try {
      if (unsubscribe) {
        unsubscribe()
      }
      loading.value = true
      selfDrive.value = []
      filteredSelfDrive.value = []

      const q = query(selfDriveCollection, ...queryOptions())
      unsubscribe = onSnapshot(q, querySnapshot => {
        selfDrive.value = []
        querySnapshot.forEach(doc => {
          selfDrive.value.push({
            id: doc.id,
            ...doc.data(),
          })
        })
        filteredSelfDrive.value = selfDrive.value
        filterSelfDrive()
      })
    } catch (e) {
      console.log(e)
    } finally {
      loading.value = false
    }
  }

  const checkIfSelfDriveExist = async () => {
    const q = query(
      selfDriveCollection,
      where('carbrand', '==', selfDriveData.value.carbrand),
      where(typeKey, '==', selfDriveData.value[typeKey]),
      where('carclass', '==', selfDriveData.value.carclass),
    )
    const querySnapshot = await getDocs(q)
    return !!querySnapshot.size
  }

  const addSelfDrive = async () => {
    try {
      loading.value = true
      error.value = null
      // if (await checkIfSelfDriveExist()) {
      //   throw new Error('Self drive car already exist!')
      // }
      const selfDrive = {
        ...selfDriveData.value,
        createdon: serverTimestamp(),
        updatedon: serverTimestamp(),
      }
      await addDoc(selfDriveCollection, selfDrive)
    } catch (e) {
      error.value = e.message
      console.log(e)
    } finally {
      loading.value = false
    }
  }

  const editSelfDrive = async id => {
    try {
      loading.value = true
      error.value = null
      const prevCarBrand = filter(selfDrive.value, carBrand => carBrand.id == id)
      let carBrand = {
        ...selfDriveData.value,
        updatedon: serverTimestamp(),
      }
      // if (
      //   prevCarBrand.length &&
      //   (prevCarBrand[0].description != carBrand.description || prevCarBrand[0][typeKey] != carBrand[typeKey])
      // ) {
      //   if (await checkIfSelfDriveExist()) {
      //     throw new Error('Car brand already exist!')
      //   }
      // }
      const docReference = doc(db, selfDriveCarCollectionName.value, id)
      await updateDoc(docReference, carBrand)
    } catch (e) {
      error.value = e.message
      console.log(e)
    } finally {
      loading.value = false
    }
  }

  const filterSelfDrive = () => {
    let filteredBrands = selfDrive.value
    if (searchQuery.value && searchQuery.value.length) {
      filteredBrands = filter(filteredBrands, item => {
        return stringContainsAny(item.carbrand + item.carclass + item[typeKey] + item.location, searchQuery.value)
      })
    }

    if (statusFilter.value && statusFilter.value.length) {
      const activeValue = statusFilter.value == 'Active'
      filteredBrands = filter(filteredBrands, ({ active }) => {
        return activeValue == active
      })
    }

    if (typeFilter.value && typeFilter.value.length) {
      filteredBrands = filter(filteredBrands, item => {
        return typeFilter.value == item[typeKey]
      })
    }

    if (locationFilter.value && locationFilter.value.length) {
      filteredBrands = filter(filteredBrands, ({ location }) => {
        return locationFilter.value == location
      })
    }

    if (brandFilter.value && brandFilter.value.length) {
      filteredBrands = filter(filteredBrands, ({ carbrand }) => {
        return brandFilter.value == carbrand
      })
    }

    if (!isNil(sortBy.value)) {
      filteredBrands = sortOrder(filteredBrands, [sortBy.value.by], [sortBy.value.order])
    }

    filteredSelfDrive.value = filteredBrands
  }

  watch([searchQuery, statusFilter, typeFilter, locationFilter, brandFilter, sortBy], () => {
    filterSelfDrive()
  })

  watch([filteredSelfDrive, itemsPerPage], () => {
    const chunkSize = itemsPerPage.value == 'All' ? filteredSelfDrive.value.length : itemsPerPage.value
    paginatedSelfDrive.value = chunk(filteredSelfDrive.value, chunkSize)
    page.value = 1
  })

  const unsubscribeSelfDrive = () => {
    unsubscribe()
  }

  fetchSelfDrive()

  return {
    selfDrive,
    page,
    itemsPerPage,
    filteredSelfDrive,
    paginatedSelfDrive,
    searchQuery,
    statusFilter,
    statusOptions,
    typeFilter,
    typeOptions,
    locationFilter,
    locationOptions,
    brandFilter,
    brandOptions,
    sortBy,
    loading,
    selfDriveData,
    blankSelfDriveData,
    error,

    unsubscribeSelfDrive,
    addSelfDrive,
    editSelfDrive,
  }
}
