<template>
  <v-hover v-slot="{ hover }">
    <v-card class="mx-auto car-fleet-card" max-width="344" outlined>
      <v-list-item two-line class="mt-4">
        <v-list-item-avatar class="" tile width="130" height="100">
          <v-img contain :src="image"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="mb-1"> {{ brandName }} {{ fleetClass }} </v-list-item-title>
          <v-list-item-subtitle class="last-modified d-flex mt-1">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" x-small>{{ icons.mdiCalendarBlankOutline }}</v-icon>
              </template>
              <span>Last Modified</span>
            </v-tooltip>
            <div class="ml-1">{{ updatedOn }}</div>
          </v-list-item-subtitle>
          <p class="mt-1">
            {{ $store.getters['appConfig/currencySymbol'] }} {{ formatNumber(price) }}
            <small v-if="country != 'UK'">/ {{ packageHours }}{{ packageHours == '24' ? 'hrs' : '' }}</small>
          </p>
        </v-list-item-content>
      </v-list-item>
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <span class="white--text">Deposit</span>
            <p>{{ $store.getters['appConfig/currencySymbol'] }} {{ formatNumber(deposit) }}</p>
          </v-col>
          <template v-if="country != 'UK'">
            <v-col cols="4">
              <span class="white--text">Free kms</span>
              <p>{{ freeKms }} / day</p>
            </v-col>
            <v-col cols="4">
              <span class="white--text">Extra Km</span>
              <p>{{ $store.getters['appConfig/currencySymbol'] }} {{ extraKmsCharge }}</p>
            </v-col>
          </template>
          <template v-else>
            <v-col cols="4">
              <span class="white--text">1-3 day rate</span>
              <p>{{ $store.getters['appConfig/currencySymbol'] }} {{ firstrate }}</p>
            </v-col>
            <v-col cols="4">
              <span class="white--text">4-6 day rate</span>
              <p>{{ $store.getters['appConfig/currencySymbol'] }} {{ secondrate }}</p>
            </v-col>
            <v-col cols="4">
              <span class="white--text">Weekly rate</span>
              <p>{{ $store.getters['appConfig/currencySymbol'] }} {{ weeklyrate }}</p>
            </v-col>
            <v-col cols="4">
              <span class="white--text">Excess</span>
              <p>{{ $store.getters['appConfig/currencySymbol'] }} {{ excessamt }}</p>
            </v-col>
          </template>
        </v-row>
      </v-card-text>
      <!-- <v-card-actions class="mt-n5">
        <span class="primary--text"> Details </span>

        <v-spacer></v-spacer>

        <v-btn icon @click="showDetail = !showDetail">
          <v-icon>{{ showDetail ? icons.mdiChevronUp : icons.mdiChevronDown }}</v-icon>
        </v-btn>
      </v-card-actions>

      <v-expand-transition>
        <div v-show="showDetail">
          <v-divider></v-divider>

          <v-card-text>
            <span v-html="extraDetail"></span>
          </v-card-text>
        </div>
      </v-expand-transition> -->
      <div>
        <div class="status-icon d-flex">
          <v-icon class="mt-1" x-small :color="isActive ? 'success' : 'error'">{{ icons.mdiCircle }}</v-icon>
          <span class="ml-2 brand-type">{{ type }}</span>
          <v-chip v-if="soldout" class="ml-1 v-chip-light-bg error--text" color="error" small>SOLD OUT</v-chip>
          <v-img class="ml-1" v-else-if="clubmember" src="https://ik.imagekit.io/egrcxziyv/club.png" width="14"></v-img>
          <v-img
            class="ml-1"
            v-else-if="popular"
            src="https://res.cloudinary.com/doot8az4x/image/upload/v1630049069/Trendicon_uaz7ts.png"
            width="22"
          ></v-img>
        </div>
        <v-chip class="ma-2 v-chip-light-bg primary--text location-icon" color="primary" small>
          {{ location }}
        </v-chip>
        <v-icon class="ml-4 edit-icon" small @click="$emit('edit-selfdrive', id)">{{ icons.mdiPencil }}</v-icon>
      </div>
    </v-card>
  </v-hover>
</template>

<script>
import { mdiCircle, mdiPencil, mdiCalendarBlankOutline, mdiChevronUp, mdiChevronDown } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { formatNumber } from '@core/utils/filter'
import useAppConfig from '@/@core/@app-config/useAppConfig'

export default {
  components: {},
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String | undefined,
      required: true,
    },
    image: {
      type: String | undefined,
      required: true,
    },
    brandName: {
      type: String,
      required: true,
    },
    location: {
      type: String,
      required: true,
    },
    updatedOn: {
      type: String,
      required: true,
    },
    fleetClass: {
      type: String,
      required: true,
    },
    deposit: {
      type: Number,
      required: true,
    },
    freeKms: {
      type: Number,
      required: true,
    },
    extraKmsCharge: {
      type: Number,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    packageHours: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    soldout: {
      type: Boolean,
      required: false,
    },
    clubmember: {
      type: Boolean,
      required: false,
    },
    popular: {
      type: Boolean,
      required: false,
    },
    firstrate: {
      type: Number,
      required: true,
    },
    secondrate: {
      type: Number,
      required: true,
    },
    weeklyrate: {
      type: Number,
      required: true,
    },
    excessamt: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { country } = useAppConfig()
    const showDetail = ref(false)
    const extraDetail = ref(
      `${props.seat} people &#8226; ${props.luggage} bags &#8226; ${props.gear} &#8226; ${props.fuel}`,
    )
    return {
      country,
      showDetail,
      extraDetail,
      formatNumber,
      icons: {
        mdiCircle,
        mdiPencil,
        mdiCalendarBlankOutline,
        mdiChevronUp,
        mdiChevronDown,
      },
    }
  },
}
</script>

<style scoped lang="scss">
.car-fleet-card {
  .status-icon {
    position: absolute;
    cursor: pointer;
    left: 10px;
    top: 5px;
  }

  .location-icon {
    position: absolute;
    cursor: pointer;
    right: 25px;
    top: -2px;
  }

  .edit-icon {
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 8px;
    color: rgb(88, 88, 88);
  }

  .brand-type {
    font-size: 00.8rem;
  }

  .last-modified {
    font-size: 0.7rem;
  }

  .card-actions {
    position: absolute;
    cursor: pointer;
    // left: 10px;
    bottom: 0px;
  }

  .last-modified {
    font-size: 0.7rem;
  }
}
</style>
