<template>
  <v-navigation-drawer
    :value="isAddNewUserSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    @input="val => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary"> {{ id ? 'Update' : 'Add' }} Self Drive</span>
        <v-spacer></v-spacer>
        <v-btn icon small @click="$emit('update:is-add-new-user-sidebar-active', false)">
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
          <v-select
            v-model="selfDriveData[typeKey]"
            :rules="[validators.required]"
            label="Car Type *"
            :items="typeOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            hide-details="auto"
            class="mb-6"
            @change="carTypeChanged"
          >
          </v-select>

          <v-select
            v-model="selfDriveData.carbrand"
            :rules="[validators.required]"
            label="Brand *"
            :items="filterdBrandOptions"
            item-text="brand"
            item-value="brand"
            outlined
            dense
            hide-details="auto"
            class="mb-6"
            @change="brandOptionChanged"
          >
          </v-select>

          <v-select
            v-model="selfDriveData.carclass"
            :rules="[validators.required]"
            label="Class *"
            :items="filterdClassOptions"
            item-text="class"
            item-value="class"
            outlined
            dense
            hide-details="auto"
            class="mb-6"
            @change="classChanged"
          >
          </v-select>

          <v-avatar
            tile
            size="80"
            v-if="selfDriveData[imgcarmobKey].length"
            class="mb-6"
          >
            <v-img contain :src="selfDriveData[imgcarmobKey]"></v-img>
          </v-avatar>

          <v-select
            v-model="selfDriveData.location"
            :rules="[validators.required]"
            label="Location *"
            :items="locationOptions"
            item-text="description"
            item-value="description"
            outlined
            dense
            hide-details="auto"
            class="mb-6"
            @change="locationChanged"
          >
          </v-select>

          <v-select
            v-model="selfDriveData.agent_name"
            :rules="[validators.required]"
            label="Agents *"
            :items="filteredAgentOptions"
            item-text="fullname"
            item-value="fullname"
            outlined
            dense
            hide-details="auto"
            class="mb-6"
            multiple
            @change="agentsChanged"
          >
          </v-select>

          <template v-if="country != 'UK'">
            <v-text-field
              v-model="selfDriveData.color"
              outlined
              dense
              label="Color"
              placeholder="Color"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>

            <v-text-field
              v-model="selfDriveData.launch_year"
              outlined
              dense
              label="Launch Year"
              placeholder="Launch Year"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>

            <v-select
              v-model="selfDriveData.package_hours"
              :rules="[validators.required]"
              label="Package Hrs *"
              :items="packageHoursOptions"
              outlined
              dense
              hide-details="auto"
              class="mb-6"
            >
            </v-select>

            <v-text-field
              type="number"
              v-model.number="selfDriveData.kms_limit"
              outlined
              dense
              :rules="[validators.requiredLength]"
              label="Kms Limit *"
              placeholder="Kms Limit"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>

            <v-text-field
              type="number"
              v-model.number="selfDriveData.extra_km_charge"
              outlined
              dense
              :rules="[validators.requiredLength]"
              label="Extra Km Charge *"
              placeholder="Extra Km Charge"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>

            <v-text-field
              type="number"
              v-model.number="selfDriveData.price"
              outlined
              dense
              :rules="[validators.requiredLength]"
              label="Price *"
              placeholder="Price"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>
          </template>
          <template v-else>
            <v-text-field
              type="number"
              v-model.number="selfDriveData.firstrate"
              outlined
              dense
              :rules="[validators.requiredLength]"
              label="1-3 day rate *"
              placeholder="1-3 day rate"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>

            <v-text-field
              type="number"
              v-model.number="selfDriveData.secondrate"
              outlined
              dense
              :rules="[validators.requiredLength]"
              label="4-6 day rate *"
              placeholder="4-6 day rate"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>

            <v-text-field
              type="number"
              v-model.number="selfDriveData.weeklyrate"
              outlined
              dense
              :rules="[validators.requiredLength]"
              label="Weekly rate *"
              placeholder="Weekly rate"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>

            <v-text-field
              type="number"
              v-model.number="selfDriveData.excessamt"
              outlined
              dense
              :rules="[validators.requiredLength]"
              label="Excess amount *"
              placeholder="Excess amount"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>

            <v-text-field
              type="number"
              v-model.number="selfDriveData.bookingamt"
              outlined
              dense
              :rules="[validators.requiredLength]"
              label="Booking amount *"
              placeholder="Booking amount"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>
          </template>

          <v-text-field
            type="number"
            v-model.number="selfDriveData.deposit"
            outlined
            dense
            :rules="[validators.requiredLength]"
            label="Deposit *"
            placeholder="Deposit"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-switch
            v-model="selfDriveData.active"
            inset
            :color="selfDriveData.active ? 'success' : 'error'"
            :label="`${selfDriveData.active ? 'Active' : 'Inactive'}`"
          ></v-switch>

          <v-alert dense text color="error" v-if="error">
            {{ error }}
          </v-alert>

          <v-btn color="primary" class="me-3" :loading="loading" type="submit">
            {{ id ? 'Update' : 'Add' }}
          </v-btn>
          <v-btn color="secondary" outlined type="reset" @click="resetselfDriveData"> Cancel </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mdiClose, mdiCircle } from '@mdi/js'
import { find, filter, each } from 'lodash'
import { ref, watch, computed } from '@vue/composition-api'
import { required, emailValidator, requiredLength } from '@core/utils/validation'
import useSelfDrive from './useSelfDrive'
import useAppConfig from '@/@core/@app-config/useAppConfig'

export default {
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    id: {
      type: String | null,
      required: true,
    },
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    fleetOptions: {
      type: Array,
      required: true,
    },
    locationOptions: {
      type: Array,
      required: true,
    },
    agentOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { country } = useAppConfig()
    const typeKey = country.value == 'UK' ? 'ltype' : 'markltype'
    const imgcarmobKey = country.value == 'UK' ? 'imgcarmob' : 'markimgcarmob'
    const typeOptions = [
      { title: 'Luxury', value: 'Luxury' },
      { title: 'Sports Car', value: 'Sports Car' },
      { title: 'Executive', value: 'Executive' },
      { title: 'Super Car', value: 'Super Car' },
      { title: 'Standard', value: 'Standard' },
      { title: 'Premium', value: 'Premium' },
    ]
    const fileRules = [v => !!v || 'File is required', v => (v && v.size > 0) || 'File is required']
    const valid = ref(false)
    const form = ref(null)
    const imageUrl = ref('')

    const packageHoursOptions = ['24', '9am - 9pm', '12am - 12am']

    const { addSelfDrive, selfDrive, selfDriveData, blankSelfDriveData, error, loading, editSelfDrive } = useSelfDrive()

    const filterdBrandOptions = computed(() => {
      if (selfDriveData.value[typeKey] && selfDriveData.value[typeKey].length) {
        return filter(props.fleetOptions, item => item[typeKey] == selfDriveData.value[typeKey])
      }
      return []
    })

    const filterdClassOptions = computed(() => {
      if (selfDriveData.value.carbrand && selfDriveData.value.carbrand.length) {
        return filter(filterdBrandOptions.value, ({ brand }) => brand == selfDriveData.value.carbrand)
      }
      return []
    })

    const filteredAgentOptions = computed(() => {
      if (selfDriveData.value.location && selfDriveData.value.location.length) {
        return filter(props.agentOptions, ({ location_id }) => location_id == selfDriveData.value.location)
      }
      return []
    })

    const validate = () => {
      form.value.validate()
    }

    const resetForm = async () => {
      await form.value.reset()
      selfDriveData.value = JSON.parse(JSON.stringify(blankSelfDriveData))
    }

    const resetselfDriveData = () => {
      resetForm()
      error.value = null
      emit('reset-id')
      emit('update:is-add-new-user-sidebar-active', false)
    }

    const onSubmit = async () => {
      if (valid.value) {
        if (props.id) {
          await editSelfDrive(props.id)
        } else {
          await addSelfDrive()
        }
        if (!error.value) {
          resetselfDriveData()
        }
      } else {
        validate()
      }
    }

    const onFileChange = file => {
      if (!file) {
        return
      }
      createImage(file)
    }

    const createImage = file => {
      const reader = new FileReader()

      reader.onload = e => {
        imageUrl.value = e.target.result
      }
      reader.readAsDataURL(file)
    }

    const classChanged = item => {
      const selectedClass = find(filterdClassOptions.value, ['class', item])
      selfDriveData.value[imgcarmobKey] = selectedClass[imgcarmobKey]
      selfDriveData.value.brandactive = selectedClass.brandactive
      selfDriveData.value.brandimg = selectedClass.brandimg
      selfDriveData.value.carfleet_id = selectedClass.id
      selfDriveData.value.carfleetactive = selectedClass.active
    }

    const brandOptionChanged = () => {
      selfDriveData.value.carclass = ''
      selfDriveData.value[imgcarmobKey] = ''
      selfDriveData.value.brandactive = null
      selfDriveData.value.brandimg = ''
      selfDriveData.value.carfleet_id = ''
      selfDriveData.value.carfleetactive = null
    }

    const carTypeChanged = () => {
      selfDriveData.value.carbrand = ''
      brandOptionChanged()
    }

    const agentsChanged = items => {
      selfDriveData.value.agent_show = items
      selfDriveData.value.agent_active = []
      each(items, item => {
        const agentData = find(filteredAgentOptions.value, ['fullname', item])
        selfDriveData.value.agent_active.push(agentData ? agentData.active : false)
      })
    }

    const locationChanged = item => {
      const locationData = find(props.locationOptions, ['description', item])
      selfDriveData.value.locactive = locationData.active
    }

    watch(
      () => props.id,
      () => {
        if (props.id) {
          const selfD = find(selfDrive.value, ['id', props.id])
          selfDriveData.value.active = selfD.active
          selfDriveData.value.agent_active = selfD.agent_active
          selfDriveData.value.agent_name = selfD.agent_name
          selfDriveData.value.agent_show = selfD.agent_show
          selfDriveData.value.brandactive = selfD.brandactive
          selfDriveData.value.brandclass = selfD.brandclass
          selfDriveData.value.brandimg = selfD.brandimg
          selfDriveData.value.carbrand = selfD.carbrand
          selfDriveData.value.carclass = selfD.carclass
          selfDriveData.value.carfleet_id = selfD.carfleet_id
          selfDriveData.value.carfleetactive = selfD.carfleetactive
          selfDriveData.value.deposit = selfD.deposit
          selfDriveData.value.locactive = selfD.locactive
          selfDriveData.value.location = selfD.location

          if (country.value == 'UK') {
            selfDriveData.value.ltype = selfD.ltype
            selfDriveData.value.imgcarmob = selfD.imgcarmob
            selfDriveData.value.firstrate = selfD.firstrate
            selfDriveData.value.secondrate = selfD.secondrate
            selfDriveData.value.weeklyrate = selfD.weeklyrate
            selfDriveData.value.excessamt = selfD.excessamt
            selfDriveData.value.bookingamt = selfD.bookingamt
          } else {
            selfDriveData.value.markltype = selfD.markltype
            selfDriveData.value.markimgcarmob = selfD.markimgcarmob
            selfDriveData.value.color = selfD.color
            selfDriveData.value.launch_year = selfD.launch_year
            selfDriveData.value.package_hours = selfD.package_hours
            selfDriveData.value.kms_limit = selfD.kms_limit
            selfDriveData.value.extra_km_charge = selfD.extra_km_charge
            selfDriveData.value.price = selfD.price
          }

          imageUrl.value = selfD[imgcarmobKey]
        }
      },
      { immediate: true },
    )

    watch(
      () => props.isAddNewUserSidebarActive,
      () => {
        if (!props.isAddNewUserSidebarActive) {
          resetselfDriveData()
        }
      },
    )

    return {
      typeOptions,
      resetselfDriveData,
      form,
      onSubmit,
      selfDriveData,
      valid,
      validate,
      error,
      fileRules,
      imageUrl,
      onFileChange,
      loading,
      filterdBrandOptions,
      filterdClassOptions,
      classChanged,
      brandOptionChanged,
      carTypeChanged,
      filteredAgentOptions,
      packageHoursOptions,
      agentsChanged,
      locationChanged,
      country,
      typeKey,
      imgcarmobKey,
      // validation
      validators: { required, emailValidator, requiredLength },
      icons: {
        mdiClose,
        mdiCircle,
      },
    }
  },
}
</script>
